import { dateFormat } from "@ivaoaero/front-components";
import "ol/ol.css";
import { VFC } from "react";
import { Accordion, Button, Card } from 'react-bootstrap';
import { FlightPlanDTO } from "../../../../common/dto/flightplan.dto";
import { SessionDTO } from "../../../../common/dto/session.dto";
import { FlightPlan } from "../FlightPlan";
import "./index.scss";

type Props = {
  flightPlans: FlightPlanDTO[];
  session: SessionDTO;
  loading: boolean
};

const aircraftTurbulence = {
  L: 'Light',
  M: 'Medium',
  H: 'Heavy',
  J: 'Super'
}

export const FlightPlanSection: VFC<Props> = ({ flightPlans, session, loading }) => {
  if (loading || !flightPlans.length) return <></>

  const flightPlanFieldsLeft = [
    ['Departure', 'departure', (a: any) => a ? `${a.name} (${a.icao})` : "ZZZZ"],
    ['Arrival', 'arrival', (a: any) => a ? `${a.name} (${a.icao})` : "ZZZZ"],
    // @ts-ignore
    ['Aircraft/Turbulence', 'aircraft', (a: any) => a ? `${a.model} (${a.icaoCode}) / ${[a.wakeTurbulence]} (${aircraftTurbulence[a.wakeTurbulence]})` : "ZZZ"],
    ['Alternate', 'alternative', (a: any) => a ? `${a.name} (${a.icao})` : "ZZZZ"],
    ['Second alternate', 'alternative2', (a: any) => a ? `${a.name} (${a.icao})` : "ZZZZ"],
    ['Altitude', 'level', (a: any) => a],
    ['Speed', 'speed', (a: any) => a],
    ['Connected', 'remarks', () => dateFormat(session.createdAt)],
    ['Disconnected', 'remarks', () => session.completedAt ? dateFormat(session.completedAt) : "--"],
  ];

  const flightPlanFieldsRight = [
    ['Route', 'route', (a: any) => a],
    ['Remarks', 'remarks', (a: any) => a],
  ];

  return <div className="flight_plans">
    <Accordion className="flight_plans_accordion">
      {flightPlans.reverse().map((flightPlan, index) => {
        return <Card
          className={`flight_plans_card ${index === 0 ? "flight_plans_card_first" : ""} ${index === flightPlans.length - 1 ? "flight_plans_card_last" : ""}`}>
          <Card.Header className="flight_plans_card_header">
            <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`}>
              <div className="row">
                <div className="col-6 text-left">
                  Revision {flightPlan.revision}
                </div>
                <div className="col-6 text-right">
                  {dateFormat(flightPlan.createdAt)}
                </div>
              </div>
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={`${index}`}>
            <Card.Body>
              <div className={"row"}>
                <div className="col-12 col-md-6">
                  {flightPlanFieldsLeft.map(([name, key, transform]) => {
                    return <div className="row">
                      <div className="col-4 text-right font-weight-bold">{name}</div>
                      {/* @ts-ignore */}
                      <div className="col-6">{transform(flightPlan[key])}</div>
                    </div>
                  })}
                </div>
                <div className="col-12 col-md-6">
                  {flightPlanFieldsRight.map(([name, key, transform]) => {
                    return <div className="row" style={{ paddingBottom: 15 }}>
                      <div className="col-12 text-left font-weight-bold">{name}</div>
                      {/* @ts-ignore */}
                      <div className="col-12">{transform(flightPlan[key])}</div>
                    </div>
                  })}
                </div>
              </div>
              <div className={"row"} style={{ paddingTop: 15 }}>
                <div className="col-12 col-md-12">
                  <div className="row">
                    <div className="col-2 text-right font-weight-bold">Filled Flightplan</div>
                    <div className="col-10 text-left">
                      <FlightPlan flightPlan={flightPlan} session={session} />
                    </div>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      })}
    </Accordion>
  </div>;
};
